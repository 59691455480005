import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { ContrastRequest, ContrastResponse } from '../Types';
import ScClient from '../network/ScClient';

interface NetworkContextType {
    fetchContrastData: (params: any) => Promise<null | ContrastResponse>;
}

const NetworkContext = createContext<NetworkContextType>({
    fetchContrastData: async () => new ContrastResponse({task: "", dataset: "", model1Description: "", model2Description: ""}, "", "", "", "", [], {title: "", explanation: "", sections: []}, {title: "", explanation: "", sections: []}),
});

const MOCK = true;

const scClient = new ScClient();

export const UserProvider = ({ children }: any) => {
    const wrapperNoRedirect = async <Q, T>(query: Q, path: string, response: {mock: () => T}): Promise<T | null> => {
        console.log("fetching data")
        if (MOCK) {
            console.log("mocking response")
            return response.mock();
        }

        // TODO (later) go in and add all the google oath stuff
        try {
            const response = await scClient.post<T>({ ...query, path});
            return response;
        } catch (error) {
            return null;
        }
    };

    const fetchContrastData = (contrastRequest: ContrastRequest): Promise<ContrastResponse | null> =>
        wrapperNoRedirect<ContrastRequest, ContrastResponse>(contrastRequest, '/contrastData', ContrastResponse);

    return (
        <NetworkContext.Provider value={{
            fetchContrastData,
        }}>
            { children }
        </NetworkContext.Provider>
    );
};

// Hook to use the user context
export const useNetwork = () => useContext(NetworkContext);
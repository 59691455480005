import React, { useEffect, useState } from "react";
import { ContrastResponse, ContrastResponseIntroduction, ModelStatistic } from "../../Types";

import "./Contrast.css";
import { ModelStatistics } from "../ModelStatistics/ModelStatistics";
import { ComparisonOverview } from "../ComparisonOverview/ComparisonOverview";
import { colorHash } from "../../utils/colorHash";
import ReactMarkdown from "react-markdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle, faX } from "@fortawesome/free-solid-svg-icons";

export type ContrastProps = {
    response: null | ContrastResponse
}

const TitleContainer: React.FC<{ data: ContrastResponse }> = ({ data }) => {
    const model1Color = colorHash(data.model1Name);
    const model2Color = colorHash(data.model2Name);
    const [showIntroOverlay, setShowIntroOverlay] = useState(false);

    return (
        <div className="title-container">
            {
                showIntroOverlay && 
                <div className="introduction-overlay">
                    <Introduction 
                        data={data.introduction} 
                        setShowIntroOverlay={setShowIntroOverlay}
                    />
                </div>
            }
            <h1>
                {data.datasetName} on {data.taskName} <FontAwesomeIcon icon={faQuestionCircle} onClick={() => setShowIntroOverlay(true)} />
            </h1>
            <h2>
                <span style={{ color: model1Color }}>{data.model1Name}</span> vs <span style={{ color: model2Color }}>{data.model2Name}</span> 
            </h2>
        </div>
    )
}

type IntroductionProps = {
    data: ContrastResponseIntroduction,
    setShowIntroOverlay: (show: boolean) => void
}

const Introduction: React.FC<IntroductionProps> = ({ data, setShowIntroOverlay}: IntroductionProps) => {
    return (
        <div className="introduction-container">
            <div className="introduction-close" onClick={() => setShowIntroOverlay(false)}>
                <FontAwesomeIcon icon={faX} />
            </div>
            <div className="introduction-content">
                <div className="introduction-content-item">
                    <ReactMarkdown>{data.task}</ReactMarkdown>
                </div>
                <div className="introduction-content-item">
                    <ReactMarkdown>{data.dataset}</ReactMarkdown>
                </div>
                <div className="introduction-content-item">
                    <ReactMarkdown>{data.model1Description}</ReactMarkdown>
                </div>
                <div className="introduction-content-item">
                    <ReactMarkdown>{data.model2Description}</ReactMarkdown>
                </div>
            </div>
        </div>
    )
}


export const Contrast: React.FC<ContrastProps> = ({ response }) => {
    const [data, setData] = useState<null | ContrastResponse>(null);

    useEffect(() => {  
        setData(response);
    }, [response]);

    if (data === null) {
        return (<div>Loading...</div>)
    }

    return (
        <div className="contrast-container">
            <TitleContainer data={data} />
            {/* <Introduction data={data.introduction} /> */}
            <ModelStatistics modelStatistics={data.modelStatistics} />
            <ComparisonOverview 
                data={data.comparisonSections} 
                namingKey={"comparison"}
            />
            <ComparisonOverview 
                data={data.bothWrongSections} 
                namingKey={"both-wrong"}
            />
        </div>
    )
}
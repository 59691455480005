import { useEffect, useState } from "react";
import { useNetwork } from "../../contexts/NetworkContext";
import { ContrastResponse } from "../../Types";
import { Contrast } from "../../components/Contrast/Contrast";

import "./ContrastPage.css";

const ContrastPage = () => {
    const { fetchContrastData } = useNetwork();
    const [ContrastData, setContrastData] = useState<null | ContrastResponse>(null);

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetchContrastData({});
            setContrastData(response);
        };
        fetchData();
    }, [])

    return (
        <div className="ContrastPage">
            <Contrast 
                response={ContrastData} 
            />
        </div>
    )
}

export default ContrastPage;
import React, { useState } from 'react'
import ReactMarkdown from "react-markdown"
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCopy, faEye, faLanguage, faMicrophone } from '@fortawesome/free-solid-svg-icons'
import './CodeSnippets.css'

const CODE_SNIPPETS = [
    {
        task: 'OCR',
        icon: faEye,
        language: 'python',
        code: `from supercontrast import (
    OCRRequest,
    Provider,
    SuperContrastClient,
    Task,
)

def run_ocr_example():
    """
    Demonstrates Optical Character Recognition (OCR) using the GCP provider.
    This function sends an image URL to the GCP OCR service and retrieves the extracted text.
    It showcases how to initialize the SuperContrastClient for OCR tasks and handle the response.
    """
    print("Running OCR Example with GCP")
    client = SuperContrastClient(task=Task.OCR, providers=[Provider.GCP])
    request = OCRRequest(image=SAMPLE_IMAGE_URL)
    response, metadata = client.request(request)
    return response, metadata
`
    },
    {
        task: 'Translation',
        icon: faLanguage,
        language: 'python',
        code: `from supercontrast import (
    TranslationRequest,
    Provider,
    SuperContrastClient,
    Task,
)

def run_translation_example():
    """
    Demonstrates Text Translation using the Azure provider.
    This function translates a sample English text to French using Azure's translation service.
    It illustrates how to configure the SuperContrastClient for translation tasks,
    including setting source and target languages.
    """
    print("Running Translation Example with Azure")
    client = SuperContrastClient(
        task=Task.TRANSLATION,
        providers=[Provider.AZURE],
        source_language="en",
        target_language="fr",
    )
    request = TranslationRequest(text=SAMPLE_TEXT)
    response, metadata = client.request(request)
    return response, metadata
`
    },
    {
        task: 'Transcription',
        icon: faMicrophone,
        language: 'python',
        code: `from supercontrast import (
    TranscriptionRequest,
    Provider,
    SuperContrastClient,
    Task,
)

def run_transcription_example():
    """
    Demonstrates Audio Transcription using the OpenAI provider.
    This function sends an audio file URL to OpenAI's transcription service
    and retrieves the transcribed text. It demonstrates how to set up the
    SuperContrastClient for audio transcription tasks and process the results.
    """
    print("Running Transcription Example with OpenAI")
    client = SuperContrastClient(task=Task.TRANSCRIPTION, providers=[Provider.OPENAI])
    request = TranscriptionRequest(audio_file=SAMPLE_AUDIO_URL)
    response, metadata = client.request(request)
    return response, metadata
`
    }
]

export const CodeSnippets: React.FC = () => {
    const [selectedTask, setSelectedTask] = useState(CODE_SNIPPETS[0].task)
    const [copied, setCopied] = useState(false);
    const [copied2, setCopied2] = useState(false);

    const components: any = {
        code({ node, inline, className, children, ...props }: any) {
            const match = /language-(\w+)/.exec(className || '')
            return !inline && match ? (
                <SyntaxHighlighter
                    style={vscDarkPlus}
                    language={match[1]}
                    PreTag="div"
                    {...props}
                >
                    {String(children).replace(/\n$/, '')}
                </SyntaxHighlighter>
            ) : (
                <code className={className} {...props}>
                    {children}
                </code>
            )
        }
    }

    const copyToClipboard = (text: string, setCopied: React.Dispatch<React.SetStateAction<boolean>>) => {
        navigator.clipboard.writeText(text).then(() => {
            setCopied(true)
            setTimeout(() => setCopied(false), 2000)
        }).catch(err => {
        });
    }

    return (
        <div className="CodeSnippets">
            <h1>Install SuperContrast</h1>
            <div className='install-snippet'>
                <ReactMarkdown components={components}>
                    {`\`\`\`bash\npip install supercontrast\n\`\`\``}
                </ReactMarkdown>
                <button 
                    className="button-alt copy-button"
                    onClick={() => copyToClipboard('pip install supercontrast', setCopied2)}
                >
                    {copied2 ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faCopy} />}
                </button>
            </div>

            {/* <h1>Code Snippets</h1> */}
            <div className="task-selector">
                {CODE_SNIPPETS.map(snippet => (
                    <button 
                        key={snippet.task}
                        onClick={() => setSelectedTask(snippet.task)}
                        className={selectedTask === snippet.task ? 'button' : 'button-alt'}
                    >
                        <FontAwesomeIcon icon={snippet.icon} /> {snippet.task}
                    </button>
                ))}
            </div>
            <div className='code-snippet'>
                <ReactMarkdown components={components}>
                    {`\`\`\`${CODE_SNIPPETS.find(snippet => snippet.task === selectedTask)?.language.toLowerCase()}\n${CODE_SNIPPETS.find(snippet => snippet.task === selectedTask)?.code}\n\`\`\``}
                </ReactMarkdown>
                <button 
                    className="button-alt copy-button"
                    onClick={() => copyToClipboard(CODE_SNIPPETS.find(snippet => snippet.task === selectedTask)?.code || '', setCopied)}
                >
                    {copied ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faCopy} />}
                </button>
            </div>
        </div>
    )
}
import './App.css';
import { Header } from './components/Header/Header';
import { DeviceProvider } from './contexts/DeviceContext';
import { UserProvider } from './contexts/NetworkContext';
import { ThemeProvider } from './contexts/ThemeContext';
import { AppRoutes } from './navigation/AppRoutes';

import { BrowserRouter as Router } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Router>
        <ThemeProvider>
          <DeviceProvider>
            <UserProvider>
              <Header />
              <AppRoutes />
            </UserProvider>
          </DeviceProvider>
        </ThemeProvider>
      </Router>
    </div>
  );
}

export default App;

import React from 'react';
import { AuthorObject, PublicationDate } from "../../Types";
import './BlogPreview.css';
import { useNavigate } from 'react-router-dom';

export type BlogPreviewProps = {
    previewImageUrl: string;
    title: string;
    publicationData: PublicationDate;
    previewText: string;
    author: AuthorObject;
}

const BlogPreview: React.FC<BlogPreviewProps> = ({
    previewImageUrl,
    title,
    publicationData,
    previewText,
    author
}) => {
    const navigate = useNavigate();

    const formatDate = (date: PublicationDate) => {
        return `${date.month} ${date.day}, ${date.year}`;
    };

    const navigateToBlogPost = () => {
        navigate(`/blog/${title}`);
    }

    return (
        <div 
            className="blogPreview"
            onClick={navigateToBlogPost}
        >
            <div className="imageContainer">
                <img src={previewImageUrl} alt={title} className="previewImage" />
            </div>
            <div className="content">
                <h2 className="title">{title}</h2>
                <p className="date">
                    {formatDate(publicationData)}
                </p>
                <p className="previewText">{previewText}</p>
                <div className="author">
                    <img src={author.authorImageUrl} alt={author.authorName} className="authorAvatar" />
                    <div className="authorInfo">
                        <span className="authorName">{author.authorName}</span>
                        <span className="authorTitle">{author.authortTitle}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BlogPreview;
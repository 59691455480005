import BlogPreview from "../../components/BlogPreview/BlogPreview";
import "./BlogPage.css";

const exampleBlogPost = {
    previewImageUrl: '/images/blogs/ycombinator.svg',
    title: 'supercontrast is a YC backed startup',
    publicationDate: {
        day: 11,
        month: 'September',
        year: 2024
    },
    previewText: "Supercontrast, the mlops startup to bringing visibility to your stacking and bridging communication between product and engineering.",
    author: {
        authorName: 'Carter McClellan',
        authorImageUrl: '/images/carter_headshot.jpeg',
        authortTitle: 'Co-Founder'
    }
};


const BlogPage = () => {
    return (
        <div className="BlogPage">
            <BlogPreview
                previewImageUrl={exampleBlogPost.previewImageUrl}
                title={exampleBlogPost.title}
                publicationData={exampleBlogPost.publicationDate}
                previewText={exampleBlogPost.previewText}
                author={exampleBlogPost.author}
            />
        </div>
    )
}

export default BlogPage;
import React, { useState } from 'react';
import "./HamburgerIcon.css";

type HamburgerIconProps = {
  isOpen: boolean;
  onClick?: () => void;
}

export const HamburgerIcon = ({ isOpen, onClick }: HamburgerIconProps) => {

  return (
    <button
      className={`hamburger-icon ${isOpen ? 'open' : ''}`}
      onClick={onClick}
      aria-label="Menu"
    >
      <span></span>
      <span></span>
    </button>
  );
};
export class ErrorMessage {
    message: string;
    customerMessage?: string;

    constructor(message: string, customerMessage?: string) {
        this.message = message;
        this.customerMessage = customerMessage;
    }

    static humanReadable(status: number) {
        switch (status) {
            case 400:
                return "Whoops, something went wrong. Please try again.";
            case 401:
                return "You are not authorized to access this page. Please log in and try again.";
            case 403:
                return 'Access denied. You do not have persmission to access this page.';
            case 404:
                return 'Looks like the page you are looking for does not exist.';
            case 500:
                return 'Looks like something went wrong on within our systems. Please try again later.';
            default:
                return 'Unknown Error';
        }
    }

    static fromStatus(status: number) {
        return new ErrorMessage(ErrorMessage.humanReadable(status));
    }
}

export class ScError extends Error {
    status: number;
    response: ErrorMessage;

    constructor(status: number, response: ErrorMessage) {
        super(response.message);
        this.status = status;
        this.response = response;
        this.name = 'ScError';
    }

    static fromStatus(status: number) {
        return new ScError(status, ErrorMessage.fromStatus(status));
    }

    static isScError(error: any): error is ScError{
        return error instanceof ScError;
    }
}
const legibleColors: string[] = [
    '#1E90FF',  // Dodger Blue
    '#FF4500',  // Orange Red
    '#32CD32',  // Lime Green
    '#FF1493',  // Deep Pink
    '#4169E1',  // Royal Blue
    '#20B2AA',  // Light Sea Green
    '#9370DB',  // Medium Purple
    '#00CED1',  // Dark Turquoise
    '#FF6347',  // Tomato
    '#00FA9A',  // Medium Spring Green
    '#BA55D3',  // Medium Orchid
    '#F08080',  // Light Coral
    '#4682B4',  // Steel Blue
    '#D2691E',  // Chocolate
];

export const colorHash = (str: string): string => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash = hash & hash; // Convert to 32-bit integer
    }
    const index = Math.abs(hash) % legibleColors.length;
    return legibleColors[index];
}
import "./HomePage.css"
import { Hero } from "../../components/Hero/Hero";
import { ProviderList } from "../../components/ProviderList/ProviderList";
import { CodeSnippets } from "../../components/CodeSnippets/CodeSnippets";

export const HomePage = () => {

    return (
        <div className="HomePage">
            <Hero />
            {/* <ProviderList /> */}
            <CodeSnippets />
            <div className="HomePage-footer"/>
        </div>
  );
}
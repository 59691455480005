import { useNavigate } from "react-router-dom";
import "./ComparisonPreview.css";

type ComparisonPreviewProps = {
    title: string;
    description: string;
}

export const ComparisonPreview = (props: ComparisonPreviewProps) => {
    const {title, description} = props;

    const navigate = useNavigate();

    const navContrast = () => {
        navigate("/examples/01");
    }

    return (
        <div 
            className="comparison-preview"
            onClick={navContrast}
        >
            <h2>{title}</h2>
            <p>{description}</p>
        </div>
    )
}
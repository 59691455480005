import React from 'react';
import { ModelStatistic } from "../../Types";
import "./ModelStatistics.css";
import { colorHash } from '../../utils/colorHash';

type ModelStatisticsProps = {
    modelStatistics: ModelStatistic[];
}

export const ModelStatistics: React.FC<ModelStatisticsProps> = ({ modelStatistics }) => {
    if (modelStatistics.length === 0) {
        return <div>No data available</div>;
    }

    const headers = Object.keys(modelStatistics[0]);

    return (
        <div className="model-statistics">
            <div className="statistics-title">Model Statistics</div>
            <table>
                <thead>
                    <tr>
                        {headers.map((header) => (
                            <th key={header}>{header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {modelStatistics.map((stat, index) => (
                        <tr key={index}>
                            {headers.map((header) => (
                                <td key={`${index}-${header}`}>
                                    {header === 'modelName' ? (
                                        <span style={{ color: colorHash(stat.modelName) }}>
                                            {stat.modelName}
                                        </span>
                                    ) : (
                                        stat[header as keyof ModelStatistic]
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};
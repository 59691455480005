import "./BlogPostPage.css";

const blogData = {
    publicationDate: {
        day: 11,
        month: 'September',
        year: 2024
    },
    author: {
        authorName: 'Carter McClellan',
        authorImageUrl: '/images/carter_headshot.jpeg',
        authortTitle: 'Co-Founder'
    },
    paragraphs: [
        "We're excited to announce that Supercontrast has been accepted into Y Combinator! The team will be joining in the Fall 2024 cohort working with with group partners David Lieb, Garry Tan, Pete Koomen, and Jon Xu.",
        "Supercontrast is building no-code model evaluation tools designed to make it easier for backend engineers, product managers, and quality assurance engineers to deploy models with confidence! By their nature, machine learning models can be hard to interpret and fail in unexpected ways which a 'hard metric' can miss.",
        "Our reporting software is built to catch the edge cases that mean the most to your business and bring them right to the forefront. We know we built something good because in the short time since launching, we've already used Supercontrast to improve the performance of our own models!",
        "Stay tuned if you'd like to work with us or learn more about our journey. We're excited to share more about our progress and the tools we're building."
    ]
};

const BlogPostPage = () => {
    const author = blogData.author;
    return (
        <div className="BlogPostPage">
            <div className="blog-post-container">
                <div className="blog-header">
                    <h1>Supercontrast is a Y Combinator-Backed Startup</h1>
                    <div className="author">
                        <img src={author.authorImageUrl} alt={author.authorName} className="authorAvatar" />
                        <div className="authorInfo">
                            <span className="authorName">{author.authorName}</span>
                            <span className="authorTitle">{author.authortTitle}</span>
                        </div>
                    </div>
                    {
                        blogData.paragraphs.map((paragraph, index) => (
                            <p key={index} className="paragraph">{paragraph}</p>
                        ))
                    }
                </div>

            </div>
        </div>
    )
}

export default BlogPostPage;
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { ErrorMessage, ScError} from './ScError';

const URL = process.env.REACT_APP_SC_URL!;
const API_KEY = process.env.REACT_APP_SC_API_KEY!;

class ScClient {
    private httpClient: AxiosInstance;
    private url: string;
    private headers: any;
    private defaultTimeout: number;

    constructor(defaultTimeout: number = 20000) {
        this.url = URL;
        this.headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${API_KEY}`
        };
        this.defaultTimeout = defaultTimeout;

        this.httpClient = axios.create({
            baseURL: this.url,
            headers: this.headers,
        });

        this.setupInterceptors();
    }

    private setupInterceptors(): void {
        this.httpClient.interceptors.request.use(request => {
            console.log('Starting Request', request);
            return request;
        });

        this.httpClient.interceptors.response.use(response => {
            console.log('Received Response', response);
            return response;
        }, error => {
            console.error('Error:', error.response);
            return Promise.reject(error);
        });
    }

    public async get<T>(): Promise<T> {
        try {
            const response = await this.httpClient.get<T>(this.url, { timeout: this.defaultTimeout });
            return response.data;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    }

    public async post<T>(data: any, timeout?: number): Promise<T> {
        try {
            const config: AxiosRequestConfig = {
                timeout: timeout || this.defaultTimeout
            };
            const response = await this.httpClient.post<T>(this.url, data, config);
            return response.data;
        } catch (error: unknown) {
            if (error instanceof Error && 'response' in error) {
                const backendError = error as {
                    response: {
                        status: number;
                        data: { 
                            message: string;
                            customerMessage?: string;
                        };
                    }
                };
        
                const errorMsg = backendError.response.data.message;
                const errorCustomerMsg = backendError.response.data.customerMessage;
                const response = new ErrorMessage(errorMsg, errorCustomerMsg)
        
                throw new ScError(backendError.response.status, response);
            } else {
                throw new Error('An unexpected error occurred');
            }
        }
    }
}

export default ScClient;
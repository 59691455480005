import "./AppRoutes.css"

import { Route, Routes } from "react-router-dom"
import { HomePage } from "../pages/HomePage/HomePage"
import ComparatorPage from "../pages/ContrastPage/ContrastPage"
import BlogPage from "../pages/BlogPage/BlogPage"
import ExamplesPage from "../pages/ExamplesPage/ExamplesPage"
import BlogPostPage from "../pages/BlogPostPage/BlogPostPage"
import DataEntryPage from "../pages/DataEntry/DataEntryPage"

export const AppRoutes = () => {
    return (
        <div className="Routes">
            <Routes>
                <Route path="/" element={<HomePage/>} />
                <Route path="/blog" element={<BlogPage />} />
                <Route path="/blog/:id" element={<BlogPostPage />} />
                <Route path="/examples" element={<ExamplesPage />} />
                <Route path="/examples/:id" element={<ComparatorPage/>} />
                {/* <Route path="/demo" element={<DataEntryPage/>} /> */}
            </Routes>
        </div>
    )
}
import { useTheme } from "../../contexts/ThemeContext";
import "./YcLogo.css";

export const YcLogo = () => {
    const { isDarkMode } = useTheme();
    if (!isDarkMode) {
        return (
            <div className="yc-logo">
                <svg 
                    version="1.1" 
                    id="Layer_1" 
                    xmlns="http://www.w3.org/2000/svg" 
                    xmlnsXlink="http://www.w3.org/1999/xlink" 
                    x="0px" 
                    y="0px"
                    viewBox="0 0 1604 320" 
                    style={{enableBackground: "new 0 0 1604 320"} as React.CSSProperties}
                    xmlSpace="preserve"
                >
                    <style type="text/css">
                        {
                            `
                            .st0{fill-rule:evenodd;clip-rule:evenodd;fill:#FB651E;}
                            .st1{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
                            .st2{fill-rule:evenodd;clip-rule:evenodd;fill:#231F20;}
                            `
                        }
                    </style>
                    <polygon id="Fill-1" className="st0" points="0,320 320,320 320,0 0,0 "/>
                    <polygon id="Fill-2" className="st1" points="173,175.9 173,247 146,247 146,175.9 77.1,73 110,73 159.6,149 209,73 241.9,73 "/>
                    <g id="Group-18" transform="translate(425.853900, 73.217000)">
                        <path id="Fill-3" className="st2" d="M81.8,34.1C52.2,34.1,30,57,30,86.6s22.2,52.7,51.8,52.7c19,0,35.5-9.8,44.4-26.3l25.4,15.1
                            c-14.2,24.3-40.8,40.1-69.8,40.1C36.2,168.1,0,131.9,0,86.6C0,41.2,36.2,5,81.8,5c29.3,0,55.6,15.6,69.8,40.1l-25.4,15.1
                            C117.2,43.7,100.8,34.1,81.8,34.1"/>
                        <path id="Fill-5" className="st2" d="M249.2,107.2c0-18.5-13.5-32.5-30.9-32.5c-17.9,0-31.4,14-31.4,32.5s13.5,32.5,31.4,32.5
                            C235.4,139.7,249.2,125.7,249.2,107.2 M157.8,107.2c0-33.4,26.8-60.2,60.5-60.2c33.2,0,60,26.8,60,60.2s-26.8,60.2-60,60.2
                            C184.6,167.4,157.8,140.6,157.8,107.2"/>
                        <path id="Fill-7" className="st2" d="M467.1,97.6v67.2h-29v-62.1c0-17.4-8.5-28.4-21.3-28.4c-13.1,0-22.7,11-22.7,28.4v62.1h-28v-62.1
                            c0-17.4-8.8-28.4-21.7-28.4c-12.9,0-22.3,11-22.3,28.4v62.1h-29v-115h29v14.7c7.1-11,18.1-17.9,31.6-17.9
                            c14.7,0,26.3,8.3,33.4,21.2c7.3-11.5,20.8-21.2,37.8-21.2C450.4,46.6,467.1,69.1,467.1,97.6"/>
                        <path id="Fill-9" className="st2" d="M570.2,108.4c0-19.2-11.9-33-28.6-33c-16.3,0-28.6,13.1-28.6,32.7c0,19.5,12.6,32.7,29.1,32.7
                            C558.3,140.7,570.2,127.2,570.2,108.4 M547.7,167.8c-16,0-27.8-7.3-34.6-17.4v14.4h-29V4.8h29v59c7-9.4,18.8-16.2,34.1-16.2
                            c29.7,0,52.4,25.6,52.9,60.1C600.6,142.6,577.4,167.8,547.7,167.8"/>
                        <path id="Fill-11" className="st2" d="M612.1,164.8h29v-115h-29V164.8z M608.4,17.2c0-9.8,8.2-17.2,18.1-17.2c9.6,0,17.6,7.3,17.6,17.2
                            c0,9.8-8,17.2-17.6,17.2C616.7,34.3,608.4,27,608.4,17.2L608.4,17.2z"/>
                        <path id="Fill-13" className="st2" d="M766.1,98.1v66.7h-28v-60.5c0-18.5-9.7-30-23.7-30c-15.4,0-26.3,14-26.3,30.5v60h-28v-115h28
                            v14.5c7.8-10.8,20.3-17.7,34.9-17.7C748.8,46.6,766.1,68.6,766.1,98.1"/>
                        <path id="Fill-14" className="st2" d="M854.1,113.8v-2.5l-29.3,5.7c-9.8,2.3-15.8,7.3-15.8,14c0,7.1,6.5,12.4,17.2,12.1
                            C841.6,142.6,854.1,129.2,854.1,113.8 M882.1,95.4v69.4h-28v-13.3c-8.5,9.2-21.8,16-36.5,16.3c-19.5,0-36.7-12.8-36.7-34.8
                            c0-20.8,15.6-32.7,38.8-37.3l33.7-6.4c-2.3-9.6-9.6-16.9-20.7-16.9c-11,0-21.3,6.9-27.5,15.6l-21.1-15.3
                            c10.8-15.1,29.8-25.2,48.7-25.2C861,47.3,882.1,66.8,882.1,95.4"/>
                        <path id="Fill-15" className="st2" d="M941.1,75.8V124c0,9.4,4.1,13.7,12.6,13.7h14.4v27h-18.4c-24.5,0-36.6-12-36.6-37.2V75.8h-21v-26
                            h20V21.4l29-8.9v37.3h27v26H941.1z"/>
                        <path id="Fill-16" className="st2" d="M1069,107.2c0-18.5-13.5-32.5-30.9-32.5c-17.9,0-31.4,14-31.4,32.5s13.5,32.5,31.4,32.5
                            C1055.2,139.7,1069,125.7,1069,107.2 M977.6,107.2c0-33.4,26.8-60.2,60.5-60.2c33.2,0,60,26.8,60,60.2s-26.8,60.2-60,60.2
                            C1004.4,167.4,977.6,140.6,977.6,107.2"/>
                        <path id="Fill-17" className="st2" d="M1178.1,47.3v27.9c-25.9,0-37,12.6-37,29.1v60.5h-28v-115h28v14.4
                            C1149,53.9,1161.7,47.3,1178.1,47.3"/>
                    </g>
                </svg>
            </div>
        )
    }
        
    // Dark Mode Logo
    return (
        <div className="yc-logo">
            <svg 
                version="1.1" 
                id="Layer_1" 
                xmlns="http://www.w3.org/2000/svg" 
                xmlnsXlink="http://www.w3.org/1999/xlink" 
                x="0px" 
                y="0px"
                viewBox="0 0 1604 320" 
                style={{enableBackground: "new 0 0 1604 320"} as React.CSSProperties}
                xmlSpace="preserve"
            >
                <style type="text/css">
                    {
                        `
                        .st0{fill-rule:evenodd;clip-rule:evenodd;fill:#FB651E;}
                        .st1{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
                        .st2{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
                        `
                    }
                </style>
                <polygon id="Fill-1" className="st0" points="0,320 320,320 320,0 0,0 "/>
                <polygon id="Fill-2" className="st1" points="173,175.9 173,247 146,247 146,175.9 77.1,73 110,73 159.6,149 209,73 241.9,73 "/>
                <g id="Group-18" transform="translate(425.853900, 73.217000)">
                    <path id="Fill-3" className="st2" d="M81.8,34.1C52.2,34.1,30,57,30,86.6s22.2,52.7,51.8,52.7c19,0,35.5-9.8,44.4-26.3l25.4,15.1
                        c-14.2,24.3-40.8,40.1-69.8,40.1C36.2,168.1,0,131.9,0,86.6C0,41.2,36.2,5,81.8,5c29.3,0,55.6,15.6,69.8,40.1l-25.4,15.1
                        C117.2,43.7,100.8,34.1,81.8,34.1"/>
                    <path id="Fill-5" className="st2" d="M249.2,107.2c0-18.5-13.5-32.5-30.9-32.5c-17.9,0-31.4,14-31.4,32.5s13.5,32.5,31.4,32.5
                        C235.4,139.7,249.2,125.7,249.2,107.2 M157.8,107.2c0-33.4,26.8-60.2,60.5-60.2c33.2,0,60,26.8,60,60.2s-26.8,60.2-60,60.2
                        C184.6,167.4,157.8,140.6,157.8,107.2"/>
                    <path id="Fill-7" className="st2" d="M467.1,97.6v67.2h-29v-62.1c0-17.4-8.5-28.4-21.3-28.4c-13.1,0-22.7,11-22.7,28.4v62.1h-28v-62.1
                        c0-17.4-8.8-28.4-21.7-28.4c-12.9,0-22.3,11-22.3,28.4v62.1h-29v-115h29v14.7c7.1-11,18.1-17.9,31.6-17.9
                        c14.7,0,26.3,8.3,33.4,21.2c7.3-11.5,20.8-21.2,37.8-21.2C450.4,46.6,467.1,69.1,467.1,97.6"/>
                    <path id="Fill-9" className="st2" d="M570.2,108.4c0-19.2-11.9-33-28.6-33c-16.3,0-28.6,13.1-28.6,32.7c0,19.5,12.6,32.7,29.1,32.7
                        C558.3,140.7,570.2,127.2,570.2,108.4 M547.7,167.8c-16,0-27.8-7.3-34.6-17.4v14.4h-29V4.8h29v59c7-9.4,18.8-16.2,34.1-16.2
                        c29.7,0,52.4,25.6,52.9,60.1C600.6,142.6,577.4,167.8,547.7,167.8"/>
                    <path id="Fill-11" className="st2" d="M612.1,164.8h29v-115h-29V164.8z M608.4,17.2c0-9.8,8.2-17.2,18.1-17.2c9.6,0,17.6,7.3,17.6,17.2
                        c0,9.8-8,17.2-17.6,17.2C616.7,34.3,608.4,27,608.4,17.2L608.4,17.2z"/>
                    <path id="Fill-13" className="st2" d="M766.1,98.1v66.7h-28v-60.5c0-18.5-9.7-30-23.7-30c-15.4,0-26.3,14-26.3,30.5v60h-28v-115h28
                        v14.5c7.8-10.8,20.3-17.7,34.9-17.7C748.8,46.6,766.1,68.6,766.1,98.1"/>
                    <path id="Fill-14" className="st2" d="M854.1,113.8v-2.5l-29.3,5.7c-9.8,2.3-15.8,7.3-15.8,14c0,7.1,6.5,12.4,17.2,12.1
                        C841.6,142.6,854.1,129.2,854.1,113.8 M882.1,95.4v69.4h-28v-13.3c-8.5,9.2-21.8,16-36.5,16.3c-19.5,0-36.7-12.8-36.7-34.8
                        c0-20.8,15.6-32.7,38.8-37.3l33.7-6.4c-2.3-9.6-9.6-16.9-20.7-16.9c-11,0-21.3,6.9-27.5,15.6l-21.1-15.3
                        c10.8-15.1,29.8-25.2,48.7-25.2C861,47.3,882.1,66.8,882.1,95.4"/>
                    <path id="Fill-15" className="st2" d="M941.1,75.8V124c0,9.4,4.1,13.7,12.6,13.7h14.4v27h-18.4c-24.5,0-36.6-12-36.6-37.2V75.8h-21v-26
                        h20V21.4l29-8.9v37.3h27v26H941.1z"/>
                    <path id="Fill-16" className="st2" d="M1069,107.2c0-18.5-13.5-32.5-30.9-32.5c-17.9,0-31.4,14-31.4,32.5s13.5,32.5,31.4,32.5
                        C1055.2,139.7,1069,125.7,1069,107.2 M977.6,107.2c0-33.4,26.8-60.2,60.5-60.2c33.2,0,60,26.8,60,60.2s-26.8,60.2-60,60.2
                        C1004.4,167.4,977.6,140.6,977.6,107.2"/>
                    <path id="Fill-17" className="st2" d="M1178.1,47.3v27.9c-25.9,0-37,12.6-37,29.1v60.5h-28v-115h28v14.4
                        C1149,53.9,1161.7,47.3,1178.1,47.3"/>
                </g>
            </svg>
        </div>
    )
}
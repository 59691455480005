import Markdown, { Components } from "react-markdown";
import { ComparativeSection, DataPoints, ModelLabel } from "../../Types";
import { colorHash } from "../../utils/colorHash";
import "./Comparison.css";
import ReactMarkdown from "react-markdown";
import { Carousel } from "../Carousel/Carousel";

const customComponents = {
    p: ({ children }: any) => <span className="custom-paragraph">{children}</span>
};

type DataPointComponentProps = {
    data: DataPoints
}

const DataPointComponent: React.FC<DataPointComponentProps> = ({ data }) => {
    return (
        <div className="datapoint-container">
            <div className="quote-container">
                <p className="quote-content">"{data.text}"</p>
            </div>
            <table className="model-results-table">
                <thead>
                    <tr>
                        <th>Model Name</th>
                        <th>Model Prediction</th>
                        <th>Correct Label</th>
                    </tr>
                </thead>
                <tbody>
                    {data.modelLabels.map((modelLabel: ModelLabel, index: number) => {
                        const modelColor = colorHash(modelLabel.model);
                        return (
                            <tr key={index}>
                                <td style={{ color: modelColor }}>{modelLabel.model}</td>
                                <td>
                                    {
                                        data.label == modelLabel.label ?
                                        (
                                            <div className="correct-label">
                                                {modelLabel.label}
                                            </div>
                                        ) : (
                                            <div className="wrong-label">
                                                {modelLabel.label}
                                            </div>
                                        )
                                    }
                                </td>
                                <td>
                                    <div className="correct-label">
                                        {data.label}
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}

type ComparisonProps = {
    data: ComparativeSection
    namingKey: string
}

export const Comparison: React.FC<ComparisonProps> = (props: ComparisonProps) => {
    const { data, namingKey } = props;
    const winningModelColor = data.winningModel ? colorHash(data.winningModel) : '';

    return (
        <div id={`${namingKey}-${data.id}`} className="comparison-container">
            <div className="comparison-title">
                {data.winningModel ? (
                    <>
                        <h2>
                            {data.title} 
                        </h2>
                        <div>
                            (winner: <span style={{ color: winningModelColor }}>{data.winningModel}</span>)
                        </div> 
                    </>
                ) : 
                    <h2>{data.title}</h2>
                }
            </div>
            <div className="comparison-explanation">
                <ReactMarkdown components={customComponents}
                >
                    {data.explanation}
                </ReactMarkdown>
            </div>
            <div className="comparison-datapoints">
                {
                    <Carousel 
                        initialItems={
                            data.data.map((dataPoint, index) => {
                                return (
                                    <div className="datapoint-plus-index-container">
                                        <DataPointComponent key={index} data={dataPoint} />
                                    </div>
                                )
                            })
                        }
                    />
                }
            </div>
        </div>
    )
}
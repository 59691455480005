import React, { useState, useEffect, useCallback, ReactNode, ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import './Carousel.css';
import ReactMarkdown from 'react-markdown';

// export type CarouselItems = {
//     desc: string;
//     example: string;
// }

export type CarouselItem = ReactElement;

type CarouselProps = {
    initialItems: CarouselItem[];
    autoScrollInterval?: number;
}


export const Carousel: React.FC<CarouselProps> = ({ initialItems, autoScrollInterval = 5000 }) => {
    const [items] = useState([...initialItems]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const [direction, setDirection] = useState<'next' | 'prev' | null>(null);

    const rotateItems = useCallback((newDirection: 'next' | 'prev') => {
        if (isTransitioning) return;
        setIsTransitioning(true);
        setDirection(newDirection);

        // Delay the index update until after the transition
        setTimeout(() => {
            setCurrentIndex((prevIndex) => {
                if (newDirection === 'next') {
                    return (prevIndex + 1) % items.length;
                } else {
                    return (prevIndex - 1 + items.length) % items.length;
                }
            });
            setIsTransitioning(false);
            setDirection(null);
        }, 500); // This should match your transition duration
    }, [isTransitioning, items.length]);

    const goToNext = useCallback(() => rotateItems('next'), [rotateItems]);
    const goToPrevious = useCallback(() => rotateItems('prev'), [rotateItems]);

    // useEffect(() => {
    //     const timer = setInterval(goToNext, autoScrollInterval);
    //     return () => clearInterval(timer);
    // }, [goToNext, autoScrollInterval]);

    const getVisibleItems = () => {
        const itemCount = items.length;
        return [
            items[(currentIndex - 1 + itemCount) % itemCount],
            items[currentIndex],
            items[(currentIndex + 1) % itemCount]
        ];
    };

    const getTransform = () => {
        if (!isTransitioning) return 'translateX(-100%)';
        return direction === 'next' ? 'translateX(-200%)' : 'translateX(0)';
    };

    return (
        <div className="carousel-container">
            <div 
                className="carousel-items"
                style={{
                    transform: getTransform(),
                    transition: isTransitioning ? 'transform 0.5s ease-in-out' : 'none',
                }}
            >
                {getVisibleItems().map((item, index) => (
                    <div key={`${currentIndex}-${index}`} className="carousel-item">
                        {item}
                    </div>
                ))}
            </div>
            <button className="button-lite carousel-button carousel-button-left" onClick={goToPrevious}>
                <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <button className="button-lite carousel-button carousel-button-right" onClick={goToNext}>
                <FontAwesomeIcon icon={faChevronRight} />
            </button>
        </div>
    );
};
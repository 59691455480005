import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleHalfStroke, faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import { useTheme } from '../../contexts/ThemeContext';
import { useDevice } from '../../contexts/DeviceContext';
import { HamburgerIcon } from '../HamburgerIcon/HamburgerIcon';

import './Header.css';

export const Header = () => {
    const navigate = useNavigate();
    const {isDarkMode, toggleTheme} = useTheme();
    const {isMobile} = useDevice();
    const [isScrolled, setIsScrolled] = useState(false);

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 10) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const navHome = () => {
        setMobileMenuOpen(false);
        navigate("/");
    };

    const navBlog = () => {
        setMobileMenuOpen(false);
        navigate("/blog");
    }

    const navExamples = () => {
        setMobileMenuOpen(false);
        navigate("/examples");
    }

    return (
        <div className={`header ${isScrolled ? 'scrolled' : ''}`}>
            <div className='header-container'>
                <div onClick={navHome} className={isMobile ? "logo mobile" : "logo"}>
                    supercontrast &nbsp; <FontAwesomeIcon icon={faCircleHalfStroke} />
                </div>
                <div className={isMobile ? 'left-container mobile' : 'left-container'}>
                    {
                        isMobile ? 
                        (
                            <>
                                <button onClick={toggleTheme} className="theme-toggle" aria-label="Toggle theme">
                                    {isDarkMode ? <FontAwesomeIcon icon={faSun} /> : <FontAwesomeIcon icon={faMoon} />}
                                </button>
                                <HamburgerIcon 
                                    isOpen={mobileMenuOpen}
                                    onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                                />
                            </>
                        )
                        : (
                            <>
                                <button className='button-alt' onClick={navBlog}>Blog</button>
                                <div onClick={toggleTheme} className="theme-toggle" aria-label="Toggle theme">
                                    {isDarkMode ? <FontAwesomeIcon icon={faSun} /> : <FontAwesomeIcon icon={faMoon} />}
                                </div>
                            </>
                        )
                    }
                    
                </div>
            </div>
            {
                isMobile && mobileMenuOpen && (
                    <div className="mobile-menu">
                        <div className="mobile-menu-links">
                            {/* <button className='button-alt'>Solutions</button> */}
                            <button className='button-alt full-width' onClick={navBlog}>Blog</button>
                        </div>
                    </div>
                )
            }
        </div>
    );
};
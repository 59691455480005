import { SectionsOverview } from "../../Types"
import { Comparison } from "../Comparison/Comparison";
import "./ComparisonOverview.css"

type ComparisonOverviewProps = {
    data: SectionsOverview
    namingKey: string
}

export const ComparisonOverview: React.FC<ComparisonOverviewProps> = (props: ComparisonOverviewProps) => {
    const { data, namingKey } = props;
    return (
        <div className="comparison-overview-container">
            <div className="comparison-overview-title">
                {data.title}
            </div>
            <div className="comparison-overview-explanation">
                {data.explanation}
                <div className="comparison-overview-toc">
                    {
                        data.sections.map((section, index) => {
                            return (
                                <a key={index} href={`#${namingKey}-${section.id}`}>{index+1}. {section.title}</a>
                            )
                        })
                    }
                </div>
            </div>
            <div className="comparison-overview-sections">
                {data.sections.map((section, index) => {
                    return (
                        <Comparison 
                            key={index} 
                            data={section} 
                            namingKey={namingKey}
                        />
                    )
                })}
            </div>
        </div>
    )
}
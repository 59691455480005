import React from 'react';
import { faDiscord, faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDevice } from "../../contexts/DeviceContext";
import { useTheme } from "../../contexts/ThemeContext";
import "./Hero.css";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { YcLogo } from "../YcLogo/YcLogo";

const DISCORD_URL = "https://discord.com/invite/G6emkXjAm2";
const GITHUB_URL = "https://github.com/supercontrast-ai/supercontrast";
const LINKEDIN_URL = "https://www.linkedin.com/in/carter-mcclellan/";

export const Hero = () => {
    const {isMobile} = useDevice();

    return (
        <div className="Hero">
            <div className={`body ${isMobile ? "mobile" : ""}`}>
                <h1 className="h1-no-margin">
                    Model Hub for Open & Closed Source
                </h1>
                <h3>
                    Supercontrast helps developers test and deploy deep learning models across all major cloud providers.
                </h3>
                <div className="social-container">
                    <button className="button-alt social-item" onClick={() => window.open(GITHUB_URL, "_blank")}>
                        <h1 className="h1-no-margin">
                            <FontAwesomeIcon icon={faGithub} className="social-icon"/>
                        </h1>
                        <p>
                            view source
                        </p>
                    </button>
                    <button className="button-alt social-item" onClick={() => window.open(DISCORD_URL, "_blank")}>
                        <h1 className="h1-no-margin">
                            <FontAwesomeIcon icon={faDiscord} className="social-icon"/>
                        </h1>
                        <p>
                            join discord
                        </p>
                    </button>
                    <button className="button-alt social-item" onClick={() => window.open(LINKEDIN_URL, "_blank")}>
                        <h1 className="h1-no-margin">
                            <FontAwesomeIcon icon={faLinkedin} className="social-icon"/>
                        </h1>
                        <p>
                            contact us
                        </p>
                    </button>
                </div>
            </div>
            <div className="footer">
                <div className="vc-container">
                    <div>
                        Backed By
                    </div>
                    <YcLogo />
                </div>
            </div>
        </div>
    );
};
import "./ExamplesPage.css";
import { ComparisonPreview } from "../../components/ComparisonPreview/ComparisonPreview";

const exampleComparison = {
    title: "Sentiment Analysis",
    description: "A comparison of Google Cloud’s Natural Language API to the AWS Comprehend API on Sentiment Analysis"
}

const ExamplesPage = () => {

    return (
        <div className="ExamplesPage">
            <ComparisonPreview
                title={exampleComparison.title}
                description={exampleComparison.description}
            />
        </div>
    )
}

export default ExamplesPage;
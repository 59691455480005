import mockComparatorResponse from "./mockData/aws_vs_gcp_contrast.json";

export type ModelLabel = {
  model: string;
  label: string;
}

export type DataPoints = {
  text: string;
  label: string;
  modelLabels: ModelLabel[];
}

export type ModelStatistic = {
  modelName: string;
  accuracy: string;
  precision: string;
  recall: string;
}

export type ComparativeSection = {
  id: number;
  title: string;
  explanation: string;
  winningModel: string;
  data: DataPoints[];
}

export type SectionsOverview = {
  title: string;
  explanation: string;
  sections: ComparativeSection[];
}

export type ContrastResponseIntroduction = {
  task: string;
  dataset: string; 
  model1Description: string; 
  model2Description:string; 
}

export class ContrastResponse{
  introduction: ContrastResponseIntroduction;
  datasetName: string;
  taskName: string;
  model1Name: string;
  model2Name: string;
  modelStatistics: ModelStatistic[];
  comparisonSections: SectionsOverview;
  bothWrongSections: SectionsOverview;

  constructor(
    introduction: ContrastResponseIntroduction,
    datasetName: string,
    taskName: string,
    model1Name: string,
    model2Name: string,
    modelStatistics: ModelStatistic[],
    comparisonSections: SectionsOverview,
    bothWrongSections: SectionsOverview
  ) {
    this.introduction = introduction;
    this.datasetName = datasetName;
    this.taskName = taskName;
    this.model1Name = model1Name;
    this.model2Name = model2Name;
    this.modelStatistics = modelStatistics;
    this.comparisonSections = comparisonSections;
    this.bothWrongSections = bothWrongSections;
  }

  static mock(): ContrastResponse {
    return mockComparatorResponse;
  }
}

export type ContrastRequest = {}

export type AuthorObject = {
  authorName: string;
  authorImageUrl: string;
  authortTitle: string;
}

export type PublicationDate = {
  day: number;
  month: string;
  year: number;
}
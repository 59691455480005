import React, { createContext, useState, useEffect, useContext } from 'react';

interface DeviceContextType {
    isMobile: boolean;
}

const DeviceContext = createContext<DeviceContextType>({
    isMobile: false,
});

export const DeviceProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      const mobileByUserAgent = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
      const mobileByWidth = window.innerWidth < 768;
      setIsMobile(mobileByUserAgent || mobileByWidth);
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);

  return (
    <DeviceContext.Provider value={{ isMobile }}>
      {children}
    </DeviceContext.Provider>
  );
};

export const useDevice = () => useContext(DeviceContext);